export const germanHeaderTranslations: { [key: string]: string } = {
  Titel: "Title",
  Benutzername: "Username",
  Konto: "Account",
  Passwort: "Password",
  "GeÃ¤ndert am": "Modified",
  "Erstellt am": "Created",
  "LÃ¤uft ab am": "Expire on",
  Beschreibung: "Description",
  "GeÃ¤ndert von": "Modified by",
};
