// FIXME: Update this file to be type safe and remove this and next line
// @ts-strict-ignore
import { TranslationService as TranslationServiceAbstraction } from "../abstractions/translation.service";

export abstract class TranslationService implements TranslationServiceAbstraction {
  // First locale is the default (English)
  supportedTranslationLocales: string[] = ["en"];
  defaultLocale = "en";
  abstract translationLocale: string;
  collator: Intl.Collator;
  localeNames = new Map<string, string>([
    ["af", "Afrikaans"],
    ["ar", "Ø§ÙØ¹Ø±Ø¨ÙØ© Ø§ÙÙØµØ­Ù"],
    ["az", "AzÉrbaycanca"],
    ["be", "ÐÐµÐ»Ð°ÑÑÑÐºÐ°Ñ"],
    ["bg", "Ð±ÑÐ»Ð³Ð°ÑÑÐºÐ¸"],
    ["bn", "à¦¬à¦¾à¦à¦²à¦¾"],
    ["bs", "bosanski jezik"],
    ["ca", "catalÃ "],
    ["cs", "ÄeÅ¡tina"],
    ["cy", "Cymraeg, y Gymraeg"],
    ["da", "dansk"],
    ["de", "Deutsch"],
    ["el", "ÎÎ»Î»Î·Î½Î¹ÎºÎ¬"],
    ["en", "English"],
    ["en-GB", "English (British)"],
    ["en-IN", "English (India)"],
    ["eo", "Esperanto"],
    ["es", "espaÃ±ol"],
    ["et", "eesti"],
    ["eu", "euskara"],
    ["fa", "ÙØ§Ø±Ø³Û"],
    ["fi", "suomi"],
    ["fil", "Wikang Filipino"],
    ["fr", "franÃ§ais"],
    ["gl", "galego"],
    ["he", "×¢××¨××ª"],
    ["hi", "à¤¹à¤¿à¤¨à¥à¤¦à¥"],
    ["hr", "hrvatski"],
    ["hu", "magyar"],
    ["id", "Bahasa Indonesia"],
    ["it", "italiano"],
    ["ja", "æ¥æ¬èª"],
    ["ka", "á¥áá áá£áá"],
    ["km", "ááááá, áááááá¶áá¶, áá¶áá¶ááááá"],
    ["kn", "à²à²¨à³à²¨à²¡"],
    ["ko", "íêµ­ì´"],
    ["lt", "lietuviÅ³ kalba"],
    ["lv", "Latvietis"],
    ["me", "ÑÑÐ½Ð¾Ð³Ð¾ÑÑÐºÐ¸"],
    ["ml", "à´®à´²à´¯à´¾à´³à´"],
    ["mr", "à¤®à¤°à¤¾à¤ à¥"],
    ["my", "ááá¬ááá¬á¸"],
    ["nb", "norsk (bokmÃ¥l)"],
    ["ne", "à¤¨à¥à¤ªà¤¾à¤²à¥"],
    ["nl", "Nederlands"],
    ["nn", "Norsk Nynorsk"],
    ["or", "à¬à¬¡à¬¼à¬¿à¬"],
    ["pl", "polski"],
    ["pt-BR", "portuguÃªs do Brasil"],
    ["pt-PT", "portuguÃªs"],
    ["ro", "romÃ¢nÄ"],
    ["ru", "ÑÑÑÑÐºÐ¸Ð¹"],
    ["si", "à·à·à¶à·à¶½"],
    ["sk", "slovenÄina"],
    ["sl", "Slovenski jezik, SlovenÅ¡Äina"],
    ["sr", "Ð¡ÑÐ¿ÑÐºÐ¸"],
    ["sv", "svenska"],
    ["te", "à°¤à±à°²à±à°à±"],
    ["th", "à¹à¸à¸¢"],
    ["tr", "TÃ¼rkÃ§e"],
    ["uk", "ÑÐºÑÐ°ÑÐ½ÑÑÐºÐ°"],
    ["vi", "Tiáº¿ng Viá»t"],
    ["zh-CN", "ä¸­æï¼ä¸­å½å¤§éï¼"],
    ["zh-TW", "ä¸­æï¼å°ç£ï¼"],
  ]);

  protected inited: boolean;
  protected defaultMessages: any = {};
  protected localeMessages: any = {};

  constructor(
    protected systemLanguage: string,
    protected localesDirectory: string,
    protected getLocalesJson: (formattedLocale: string) => Promise<any>,
  ) {
    this.systemLanguage = systemLanguage.replace("_", "-");
  }

  async init(locale?: string) {
    if (this.inited) {
      throw new Error("i18n already initialized.");
    }
    if (this.supportedTranslationLocales == null || this.supportedTranslationLocales.length === 0) {
      throw new Error("supportedTranslationLocales not set.");
    }

    this.inited = true;
    this.translationLocale = locale != null ? locale : this.systemLanguage;

    try {
      this.collator = new Intl.Collator(this.translationLocale, {
        numeric: true,
        sensitivity: "base",
      });
    } catch {
      this.collator = null;
    }

    if (this.supportedTranslationLocales.indexOf(this.translationLocale) === -1) {
      this.translationLocale = this.translationLocale.slice(0, 2);

      if (this.supportedTranslationLocales.indexOf(this.translationLocale) === -1) {
        this.translationLocale = this.defaultLocale;
      }
    }

    if (this.localesDirectory != null) {
      await this.loadMessages(this.translationLocale, this.localeMessages);
      if (this.translationLocale !== this.defaultLocale) {
        await this.loadMessages(this.defaultLocale, this.defaultMessages);
      }
    }
  }

  t(id: string, p1?: string, p2?: string, p3?: string): string {
    return this.translate(id, p1, p2, p3);
  }

  translate(id: string, p1?: string | number, p2?: string | number, p3?: string | number): string {
    let result: string;
    // eslint-disable-next-line
    if (this.localeMessages.hasOwnProperty(id) && this.localeMessages[id]) {
      result = this.localeMessages[id];
      // eslint-disable-next-line
    } else if (this.defaultMessages.hasOwnProperty(id) && this.defaultMessages[id]) {
      result = this.defaultMessages[id];
    } else {
      result = "";
    }

    if (result !== "") {
      if (p1 != null) {
        result = result.split("__$1__").join(p1.toString());
      }
      if (p2 != null) {
        result = result.split("__$2__").join(p2.toString());
      }
      if (p3 != null) {
        result = result.split("__$3__").join(p3.toString());
      }
    }

    return result;
  }

  protected async loadMessages(locale: string, messagesObj: any): Promise<any> {
    const formattedLocale = locale.replace("-", "_");
    const locales = await this.getLocalesJson(formattedLocale);
    for (const prop in locales) {
      // eslint-disable-next-line
      if (!locales.hasOwnProperty(prop)) {
        continue;
      }
      messagesObj[prop] = locales[prop].message;

      if (locales[prop].placeholders) {
        for (const placeProp in locales[prop].placeholders) {
          if (
            !locales[prop].placeholders.hasOwnProperty(placeProp) || // eslint-disable-line
            !locales[prop].placeholders[placeProp].content
          ) {
            continue;
          }

          const replaceToken = "\\$" + placeProp.toUpperCase() + "\\$";
          let replaceContent = locales[prop].placeholders[placeProp].content;
          if (replaceContent === "$1" || replaceContent === "$2" || replaceContent === "$3") {
            replaceContent = "__$" + replaceContent + "__";
          }
          messagesObj[prop] = messagesObj[prop].replace(
            new RegExp(replaceToken, "g"),
            replaceContent,
          );
        }
      }
    }
  }
}
